import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <Fragment>
            {/* <!-- info section --> */}

            <section class="info_section">
                <div class="info_container layout_padding2">
                    <div class="container">
                        <div class="info_logo">
                            {/* <a class="navbar-brand" href=""> Tro<span>Weld</span> </a> */}
                        </div>
                        <div class="info_main">
                            <div class="row">
                                <div class="col-md-3 col-lg-2">
                                    <div class="info_link-box">
                                        <h5>Quick Links</h5>
                                        <ul>
                                            <li class="active">
                                                <a class="" href="/">Home <span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="">
                                                <Link to="/about">
                                                    <p >About us </p>
                                                </Link>
                                            </li>
                                            <li class="">
                                                <Link to="/privacy-policy" >
                                                    <p >Privacy Policy </p>
                                                </Link>
                                            </li>
                                            <li class="">
                                                <Link to="/terms-condition">
                                                    <p>Terms & Condition</p>
                                                </Link>
                                            </li>
                                            {/* <li class="">
                                                <a class="" href="contact.html"> Contact </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <h5>Find the best pet service for your dog</h5>
                                    <p>In Mumbai and Navi Mumbai</p>
                                    <a className='s-a-tag' href="">Pet Grooming Services In Mumbai and Navi Mumbai</a>
                                    <a className='s-a-tag' href="">Pet Grooming Services In Mumbai and Navi Mumbai</a>
                                    <a className='s-a-tag' href="">Pet Grooming Services In Mumbai and Navi Mumbai</a>
                                    <a className='s-a-tag' href="">Pet Grooming Services In Mumbai and Navi Mumbai</a>
                                    <a className='s-a-tag' href="">Pet Grooming Services In Mumbai and Navi Mumbai</a>
                                </div>
                                <div class="col-md-3 mx-auto">
                                    <h5>social media</h5>
                                    <div class="social_box">
                                        <a href="#">
                                            <i class="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                        {/* <a href="#">
                                            <i class="fa fa-twitter" aria-hidden="true"></i>
                                        </a> */}
                                        <a href="https://www.instagram.com/easyhaibro.petcare/"
                                            target='_blank'
                                        >
                                            <i class="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                        <a href="https://www.youtube.com/@petcare.easyhaibro" target='_blank'>
                                            <i class="fa fa-youtube-play" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    {/* <h5>Our  center</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscinaliquaLoreadipiscing</p> */}
                                </div>
                            </div>
                        </div>
                        <div class="info_bottom">
                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="info_contact">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <a  class="link-box">
                                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                    <span> Location </span>
                                                </a>
                                            </div>
                                            <div class="col-md-5">
                                                <a  class="link-box">
                                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                                    <span> Call +91 9506521413 </span>
                                                </a>
                                            </div>
                                            <div class="col-md-4">
                                                <a  class="link-box">
                                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                                    <span> info@easyhaibro.com </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="info_form">
                                        <form action="">
                                            <input type="email" placeholder="Enter Your Email" />
                                            <button>
                                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end info section --> */}

            {/* <!-- footer section --> */}
            <footer class="footer_section">
                <div class="container">
                    <p>
                        &copy; <span id="displayYear"></span> All Rights Reserved By
                        <a href="https://html.design/"> easyhaibro</a>
                    </p>
                </div>
            </footer>
        </Fragment>
    )
}

export default Footer