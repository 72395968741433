import React, { Fragment } from 'react'
import pet from "../assets/images/pet.jpg"
import { useNavigate } from 'react-router-dom'
import Footer from './footer/Footer'
import PetBoarding from "../assets/svg/petBoarding.svg"
import PetGrooming from "../assets/svg/petGrooming.svg"
import PetTraining from "../assets/svg/petTraining.svg"
import DogWalk from "../assets/svg/dogWalk.svg"
import VetConsultation from "../assets/svg/vetConsult.svg"
import PackersMovers from "../assets/svg/packers.svg"

const Home = () => {
    const navigate = useNavigate()
    const handleSingup = () => {
        navigate("/form-details")
    }
    return (
        <Fragment>
            <div className="hero_area">
                {/* <!-- slider section --> */}
                <section className="slider_section">
                    <div id="customCarousel1" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="container">
                                    <div className="detail-box">
                                        <h1>
                                            Sab Kuch <br />
                                            Easy Hai Bro <br />
                                            {/* Dream Up */}
                                        </h1>
                                        <div className="btn-box">
                                            <a className="btn1 cursor-pointer" onClick={handleSingup}> Sign Up </a>
                                            <a href="" className="btn2"> Login </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="carousel-item">
                                <div className="container">
                                    <div className="detail-box">
                                        <h1>
                                            We Build <br />
                                            The Things Architects <br />
                                            Dream Up
                                        </h1>
                                        <div className="btn-box">
                                            <a href="" className="btn1"> Read More </a>
                                            <a href="" className="btn2"> Contact Us </a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="carousel-item">
                                <div className="container">
                                    <div className="detail-box">
                                        <h1>
                                            We Build <br />
                                            The Things Architects <br />
                                            Dream Up
                                        </h1>
                                        <div className="btn-box">
                                            <a href="" className="btn1"> Read More </a>
                                            <a href="" className="btn2"> Contact Us </a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="carousel_btn-box">
                            <a className="carousel-control-prev" href="#customCarousel1" role="button" data-slide="prev">
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#customCarousel1" role="button" data-slide="next">
                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                <span className="sr-only">Next</span>
                            </a>
                        </div> */}
                    </div>
                </section>
                {/* <!-- end slider section --> */}
            </div>

            {/* <!-- about section --> */}

            <section className="about_section layout_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="detail-box">
                                <div className="heading_container">
                                    <h2>Welcome to <span>easyhaibro</span></h2>
                                </div>
                                <p>
                                    Welcome to RAA EASY HAI BRO SERVICES PRIVATE LIMITED, your trusted partner in providing convenient and reliable pet care services right at your doorstep. At RAA EASY HAI BRO, we understand that your pets are more than just animals – they are cherished members of your family. That's why we are dedicated to delivering top-notch pet care services designed to make both you and your furry friends happy.
                                </p>
                                {/* <a href=""> Read More </a> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-box">
                                <img src={pet} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end about section --> */}

            {/* <!-- portfolio section --> */}

            {/* <section className="portfolio_section">
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2>Our Work Portfolio</h2>
                    </div>
                    <div className="carousel-wrap">
                        <div className="filter_box">
                            <nav className="owl-filter-bar">
                                <a href="#" className="item active" data-owl-filter="*">All</a>
                                <a href="#" className="item" data-owl-filter=".decorative">DECORATIVE</a>
                                <a href="#" className="item" data-owl-filter=".facade">FACADES </a>
                                <a href="#" className="item" data-owl-filter=".perforated">PERFORATED </a>
                                <a href="#" className="item" data-owl-filter=".railing">RAILINGS </a>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel portfolio_carousel">
                    <div className="item decorative">
                        <div className="box">
                            <div className="img-box">
                                <img src="images/p1.jpg" alt="" />
                                <div className="btn_overlay">
                                    <a href="" className=""> See More </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item facade">
                        <div className="box">
                            <div className="img-box">
                                <img src="images/p2.jpg" alt="" />
                                <div className="btn_overlay">
                                    <a href="" className=""> See More </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item perforated decorative">
                        <div className="box">
                            <div className="img-box">
                                <img src="images/p3.jpg" alt="" />
                                <div className="btn_overlay">
                                    <a href="" className=""> See More </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item railing">
                        <div className="box">
                            <div className="img-box">
                                <img src="images/p1.jpg" alt="" />
                                <div className="btn_overlay">
                                    <a href="" className=""> See More </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <!-- end portfolio section --> */}

            {/* <!-- service section --> */}

            <section className="service_section layout_padding">
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2>Our <span>Services</span></h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <a href='https://easyhaibro.com/category/pet-boarding' target='_blank' >
                                <div className="box">
                                    <div className="img-box">
                                        <img src="images/s1.png" alt="" />
                                    </div>
                                    <div className="detail-box">
                                        <img src={PetBoarding} />
                                        <h5>Pet Boarding</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <a href='https://easyhaibro.com/category/pet-grooming' target='_blank' >
                                <div className="box">
                                    <div className="img-box">
                                        <img src="images/s2.png" alt="" />
                                    </div>
                                    <div className="detail-box">
                                        <img src={PetGrooming} alt="" />
                                        <h5>Pet Grooming</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <a href='https://easyhaibro.com/category/pet-training' target='_blank' >
                                <div className="box">
                                    <div className="img-box">
                                        <img src="images/s3.png" alt="" />
                                    </div>
                                    <div className="detail-box">
                                        <img src={PetTraining} alt="" />
                                        <h5>Pet Training</h5>
                                    </div>
                                </div>
                            </a>

                        </div>
                        <div className="col-sm-6 col-md-4">
                            <a href='https://easyhaibro.com/category/dog-walk' target='_blank' >
                                <div className="box">
                                    <div className="img-box">
                                        <img src="images/s4.png" alt="" />
                                    </div>
                                    <div className="detail-box">
                                        <img src={DogWalk} alt="" />
                                        <h5>Dog Walk</h5>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-4">
                        <a href='https://easyhaibro.com/category/vet-consultation' target='_blank' >
                            <div className="box">
                                <div className="img-box">
                                    <img src="images/s5.png" alt="" />
                                </div>
                                <div className="detail-box">
                                    <img src={VetConsultation} alt="" />
                                    <h5>Vet Consultation </h5>
                                </div>
                            </div>
                            </a>

                        </div>
                        <div className="col-sm-6 col-md-4">
                        <a href='https://easyhaibro.com/packers-and-movers' target='_blank' >
                            <div className="box">
                                <div className="img-box">
                                    <img src="images/s6.png" alt="" />
                                </div>
                                <div className="detail-box">
                                    <img src={PackersMovers} alt="" />
                                    <h5>Packers & Movers</h5>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="btn-box">
                        {/* <a href=""> Read More </a> */}
                    </div>
                </div>
            </section>

            {/* <!-- end service section --> */}

            {/* <!-- contact section --> */}
            {/* <section className="contact_section">
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2>Get In <span>Touch</span></h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 px-0">
                            <div className="form_container">
                                <form action="">
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <input type="text" className="form-control" placeholder="Your Name" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-lg-6">
                                            <input type="text" className="form-control" placeholder="Phone Number" />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <select name="" id="" className="form-control wide">
                                                <option value="">Select Service</option>
                                                <option value="">Service 1</option>
                                                <option value="">Service 2</option>
                                                <option value="">Service 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <input type="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <input type="text" className="message-box form-control" placeholder="Message" />
                                        </div>
                                    </div>
                                    <div className="btn_box">
                                        <button>SEND</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 px-0">
                            <div className="map_container">
                                <div className="map">
                                    <div id="googleMap"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- end contact section --> */}

            {/* <!-- client section --> */}
            {/* <section className="client_section layout_padding">
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2>Testimonial</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div id="customCarousel2" className="carousel slide" data-ride="carousel">
                                <div className="row">
                                    <div className="col-md-11">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <div className="box">
                                                    <div className="client_id">
                                                        <div className="img-box">
                                                            <img src="images/client.jpg" alt="" />
                                                        </div>
                                                        <h5>Alex Jonson</h5>
                                                    </div>
                                                    <div className="detail-box">
                                                        <p>
                                                            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="box">
                                                    <div className="client_id">
                                                        <div className="img-box">
                                                            <img src="images/client.jpg" alt="" />
                                                        </div>
                                                        <h5>Alex Jonson</h5>
                                                    </div>
                                                    <div className="detail-box">
                                                        <p>
                                                            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <div className="box">
                                                    <div className="client_id">
                                                        <div className="img-box">
                                                            <img src="images/client.jpg" alt="" />
                                                        </div>
                                                        <h5>Alex Jonson</h5>
                                                    </div>
                                                    <div className="detail-box">
                                                        <p>
                                                            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <ol className="carousel-indicators">
                                            <li data-target="#customCarousel2" data-slide-to="0" className="active"></li>
                                            <li data-target="#customCarousel2" data-slide-to="1"></li>
                                            <li data-target="#customCarousel2" data-slide-to="2"></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- end client section --> */}
            <Footer />




        </Fragment>
    )
}

export default Home