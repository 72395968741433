import React from 'react'
import {Navbar, Container, Form, Card } from 'react-bootstrap';
import Logo from "../../assets/images/logo.png"
const MainHeader = () => {
    return (
        <Navbar className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/"><img className='h-logo' src={Logo}  alt={Logo} /></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {/* Signed in as: <a href="#login">Mark Otto</a> */}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MainHeader