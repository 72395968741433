import './App.css';
import Home from './screen/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import UserDetails from './screen/singup/UserDetails';
import "../src/assets/css/mobile.css"
import About from './screen/sub-screen/About';
import Privacy from './screen/sub-screen/Privacy';
import TermsCondition from './screen/sub-screen/TermsCondition';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/form-details" element={<UserDetails/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/privacy-policy" element={<Privacy/>} />
      <Route path="/terms-condition" element={<TermsCondition/>} />
    </Routes>
  );
}

export default App;
