import React, { useState } from 'react';
import { Container, Form, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import MainHeader from '../navbar/MainHeader';


function UserDetails() {
  const naviagte = useNavigate()
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    currentAddress: '',
    pancardNumber: '',
    serviceName: '',
    slots: '',
    password: "",
    businessName: "",
    businessAddress: "",
    businessDate: "",
    businessSlots: "",
    panCard: "",
    adharCard: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    setProgressValue((currentStep + 1) / 3);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    setProgressValue((currentStep - 1) / 3);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    Swal.fire({
      title: "Successfully completed",
      icon: "success"
    });
    naviagte("/")
  };
  const names = ["Personal Details", "Business Details", "Documents Upload"];


  const [selectedOption, setSelectedOption] = useState(null);
  const [selectSlots, setSelectSlots] = useState(null)
  const dummyOptions = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
  ];
  const dummySlots = [
    { value: '1', label: '10:00 AM' },
    { value: '2', label: '11:00 AM' },
    { value: '3', label: '12:00 Am' },
    { value: '4', label: '01:00 PM' },
    { value: '5', label: '02:00 PM' },
    { value: '6', label: '03:00 PM' },
    { value: '7', label: '04:00 PM' },
    { value: '8', label: '05:00 PM' },
    { value: '9', label: '06:00 PM' },
    { value: '10', label: '07:00 PM' },
  ];



  const handleChangeSelect = selectedOption => {
    setSelectedOption(selectedOption);
  };

  const handleChangeSlots = (selectedSlots) => {
    setSelectSlots(selectedSlots)
  }

  const [selectedAdharCard, setSelectedAdharCard] = useState(null);
  const [selectPanCard, setSelectPanCard] = useState(null);

  const handleChangeAdharCard = (event) => {
    setSelectedAdharCard(event.target.files[0]);
  };

  const handleChangePanCard = (event) => {
    setSelectPanCard(event.target.files[0]);
  };

  const [progressValue, setProgressValue] = useState(0);
  return (
    <div className='use-f'>
      <MainHeader />
      <Container className="py-5" >
        <div className='user-form top-five'>
          <Card className="custom-card">
            <div className='onboarding-form-conatiner'>

              <div className="d-flex flex-column align-items-center onboarding-left-conatiner" style={{ padding: "12px", padding: "3%" }}>
                <div className='on-left-text-top' style={{ display: "flex" }}>
                  <div className='ml-5'>
                    <h3>Onboarding</h3>
                    <p>Getting Started on Onboarding Details</p>
                  </div>
                  &nbsp;
                  &nbsp;&nbsp;
                  <div style={{ width: 40, height: 40 }}>
                    <CircularProgressbar value={progressValue} maxValue={1} text={`${Math.floor(progressValue * 100)}%`}
                      styles={{
                        path: { stroke: '#28a745', strokeWidth: '8px', strokeLinecap: 'round' },
                        trail: { stroke: '#f3f3f3' },
                        text: { fill: '#000', fontSize: '20px' }
                      }}
                    >
                    </CircularProgressbar>

                  </div>
                </div>

                <div className="vertical-progress-bar-container">
                  {names.map((name, index) => (
                    <div key={index} className="step-container">
                      <div
                        className={`step ${currentStep === index + 1 ? 'active' : ''}`}
                        onClick={() => setCurrentStep(index + 1)}
                      >
                        <div className="step-number">{index + 1}</div>
                        <div className="step-text">{name}</div>
                      </div>
                      {index !== names.length - 1 && <div className="vertical-line"></div>}
                    </div>
                  ))}
                </div>

              </div>

              <div className="onboarding-right-conatiner" style={{ backgroundColor: "#FFFFFF" }}>
                <Form onSubmit={handleSubmit} className="custom-form">
                  {currentStep === 1 && (
                    <>
                      <Form.Group controlId="name">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter full name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="name">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your phone number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="email">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className='mt-4' style={{ display: "flex", justifyContent: "space-between" }}>
                        <button className='on-boarding-left-icon-btn' onClick={prevStep}>
                        </button>
                        <button className='btn-onboarding-right' onClick={nextStep}>Next step 2 <i class="fa fa-chevron-right"></i></button>
                      </div>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <Form.Group controlId="currentAddress">
                        <Form.Label>Business Service</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Business Name"
                          name="businessName"
                          value={formData.businessName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="pancardNumber">
                        <Form.Label>Business Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Business Address"
                          name="businessAddress"
                          value={formData.businessAddress}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="pancardNumber">
                        <Form.Label>Business Date</Form.Label>
                        <Select
                          value={selectedOption}
                          onChange={handleChangeSelect}
                          options={dummyOptions}
                          isMulti
                        />
                      </Form.Group>
                      <Form.Group controlId="pancardNumber">
                        <Form.Label>Business Slots</Form.Label>
                        <Select
                          value={selectSlots}
                          onChange={handleChangeSlots}
                          options={dummySlots}
                          isMulti
                        />
                      </Form.Group>
                      <div className='mt-4' style={{ display: "flex", justifyContent: "space-between" }}>
                        <button className='on-boarding-left-icon-btn' onClick={prevStep}> <i className="fa fa-arrow-circle-o-left slaty" aria-hidden="true"></i></button>
                        <button className='btn-onboarding-right' onClick={nextStep}>Next step 3 <i class="fa fa-chevron-right"></i></button>
                      </div>
                    </>
                  )}
                  {currentStep === 3 && (
                    <>
                      <Form.Group controlId="panCardService">
                        <Form.Label>Pan Card</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter pancard number"
                          name="panCard"
                          value={formData.panCard}
                          onChange={handleChangePanCard}
                        />
                        <div>
                          <input
                            type="file"
                            id="panCardInput"
                            style={{ display: 'none' }}
                            onChange={handleChangePanCard}
                          />
                          <label htmlFor="panCardInput" style={selectPanCard ? selectedStyle : defaultStyle}>
                            {selectPanCard ? `Selected File: ${selectPanCard.name}` : 'Choose File'}
                          </label>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="adharCardService">
                        <Form.Label>Adhar Card</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter adharcard number"
                          name="adharCard"
                          value={formData.adharCard}
                          onChange={handleChangeAdharCard}
                        />
                        <div>
                          <input
                            type="file"
                            id="adharCardInput"
                            style={{ display: 'none' }}
                            onChange={handleChangeAdharCard}
                          />
                          <label htmlFor="adharCardInput" style={selectedAdharCard ? selectedStyle : defaultStyle}>
                            {selectedAdharCard ? `Selected File: ${selectedAdharCard.name}` : 'Choose File'}
                          </label>
                        </div>
                      </Form.Group>


                      <div className='mt-4' style={{ display: "flex", justifyContent: "space-between" }}>
                        <button onClick={prevStep} className='on-boarding-left-icon-btn'><i className="fa fa-arrow-circle-o-left slaty" aria-hidden="true"></i></button>
                        <button className='btn-onboarding-right' variant="primary" type="submit">Submit</button>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
}

const defaultStyle = {
  fontSize: '1.25em',
  fontWeight: '700',
  color: 'white',
  backgroundColor: '#0056b3',
  display: 'inline-block',
  padding: '5px 10px',
  cursor: 'pointer',
  borderRadius: '5px',
};

const selectedStyle = {
  ...defaultStyle,
  backgroundColor: '#45a049',
};

export default UserDetails;
